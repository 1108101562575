<template>
  <v-card class="mx-auto" :disabled="loading">
    <v-card-title>
      <v-row>
        <v-col cols="12" xs="12" md="8">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                small
                plain
                icon
                :to="{
                  name: page_route,
                }"
              >
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
            </template>
            <span v-text="'Átras'" />
          </v-tooltip>
          <span
            class="text-h6 ml-1"
            v-text="
              `${$route.meta.title} ${
                loading
                  ? ''
                  : this.data_table && this.data_table.length > 0
                  ? `(${this.data_table.length})`
                  : ''
              }`
            "
          />
        </v-col>
        <v-col cols="12" xs="12" md="4" class="text-right">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                x-small
                class="ml-1"
                color="info"
                @click.stop="faqs_dlg = true"
                :loading="faqs_ldg"
                :disabled="faqs.length == 0"
              >
                <v-icon> mdi-help </v-icon>
              </v-btn>
            </template>
            <span v-text="'Ayuda'" />
          </v-tooltip>
          <v-tooltip bottom v-if="true">
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                x-small
                dark
                color="warning"
                class="ml-1"
                :to="{
                  name: 'facturacion.edo_cuenta_aseguradora',
                }"
              >
                <v-icon> mdi-file-find </v-icon>
              </v-btn>
            </template>
            <span v-text="'Edo. cuenta aseguradora'" />
          </v-tooltip>
          <v-tooltip bottom v-if="true">
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                x-small
                dark
                color="success"
                class="ml-1"
                @click="paymentReportDialog"
              >
                <v-icon v-text="'mdi-file'" />
              </v-btn>
            </template>
            <span v-text="'Reportes'" />
          </v-tooltip>
          <v-tooltip bottom v-if="false">
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                x-small
                dark
                color="warning"
                class="ml-1"
                :to="{
                  name: 'facturacion.edos_cuenta',
                }"
              >
                <v-icon v-text="'mdi-file-document-alert'" />
              </v-btn>
            </template>
            <span v-text="'Edos. cuenta alertas'" />
          </v-tooltip>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-row dense>
        <v-col cols="12" xs="12" md="8">
          <v-text-field
            v-model="search_table"
            append-icon="mdi-magnify"
            label="Buscar..."
            single-line
            hide-details
            dense
          />
        </v-col>
        <v-col cols="12" xs="12" md="4">
          <v-select
            label="Mostrar"
            v-model="filter"
            :items="filter_options"
            item-value="id"
            :item-text="(v) => v.value"
            dense
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" xs="12">
          <v-data-table
            :headers="headers"
            :items="data_table"
            :loading="loading"
            :search="search_table"
          >
            <template v-slot:item.index="{ item }">
              <div>
                <span class="font-weight-bold" v-text="item.index" />
              </div>
            </template>
            <template v-slot:item.amount="{ item }">
              <div>
                <span v-text="numberFormat(item.amount)" />
              </div>
            </template>
            <template v-slot:item.action="{ item }">
              <v-tooltip left>
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="mr-1"
                    v-on="on"
                    fab
                    x-small
                    :color="item.bank_check ? 'warning' : 'info'"
                    dark
                    @click.prevent="payDetail(item.id)"
                  >
                    <v-icon v-text="'mdi-file-eye'" />
                  </v-btn>
                </template>
                <span v-text="'Detalle'" />
              </v-tooltip>
              <v-tooltip left>
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="mr-1"
                    v-on="on"
                    fab
                    x-small
                    dark
                    @click.prevent="paymentGroup(item.group)"
                  >
                    <v-icon v-text="'mdi-book-multiple'" />
                  </v-btn>
                </template>
                <span v-text="'Detalle de lote'" />
              </v-tooltip>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card-text>
    <!-- DIALOGS -->
    <v-dialog v-model="pay_dialog" scrollable persistent max-width="1800px">
      <v-card
        tile
        v-if="pay_dialog"
        :disabled="pay_dialog_loading"
        :loading="pay_dialog_loading"
      >
        <v-toolbar dark dense>
          <v-toolbar-title v-text="`Orden de pago ${data ? data.folio : ''}`" />
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="pay_dialog = false">
              <v-icon v-text="'mdi-close'" />
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text v-if="data">
          <v-row class="pt-3">
            <v-col cols="12">
              <v-card class="mx-auto">
                <v-card-title>
                  <h2 class="text-caption" v-text="'ASEGURADORAS'" />
                </v-card-title>
                <v-divider />
                <v-card-text>
                  <v-row dense>
                    <v-col cols="12">
                      <v-simple-table dense>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Nombre</th>
                              <th>Ingresos</th>
                              <th>Monto pago total</th>
                              <th>Monto factura SM total</th>
                              <th>Honorarios Médicos</th>
                              <th />
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(pay_insurance, i) in data.pay_insurances"
                              :key="i"
                            >
                              <td v-text="i + 1" />
                              <td v-text="pay_insurance.name" />
                              <td v-text="pay_insurance.quantity" />
                              <td
                                v-text="
                                  numberFormat(pay_insurance.payment_total)
                                "
                              />
                              <td
                                v-text="
                                  numberFormat(pay_insurance.bill_payment_total)
                                "
                              />
                              <td
                                v-text="
                                  numberFormat(pay_insurance.letters_total)
                                "
                              />
                              <td>
                                <v-tooltip left>
                                  <template v-slot:activator="{ on }">
                                    <v-btn
                                      v-on="on"
                                      fab
                                      x-small
                                      color="teal darken-3"
                                      dark
                                      @click.prevent="
                                        insurancePayDetail(pay_insurance)
                                      "
                                    >
                                      <v-icon v-text="'mdi-group'" />
                                    </v-btn>
                                  </template>
                                  <span
                                    v-text="'Concentradores | Contratantes'"
                                  />
                                </v-tooltip>
                              </td>
                            </tr>
                            <tr>
                              <td />
                              <td />
                              <td />
                              <td
                                class="font-weight-bold"
                                v-text="
                                  numberFormat(
                                    data.pay_insurances_payment_total
                                  )
                                "
                              />
                              <td
                                class="font-weight-bold"
                                v-text="
                                  numberFormat(
                                    data.pay_insurances_bill_payment_total
                                  )
                                "
                              />
                              <td
                                class="font-weight-bold"
                                v-text="
                                  numberFormat(
                                    data.pay_insurances_letters_total
                                  )
                                "
                              />
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12">
              <v-card class="mx-auto">
                <v-card-title>
                  <h2 class="text-caption" v-text="'HOSPITALES'" />
                </v-card-title>
                <v-divider />
                <v-card-text>
                  <v-row dense>
                    <v-col cols="12">
                      <v-simple-table dense>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Nombre</th>
                              <th>Ingresos</th>
                              <th>Monto pago total</th>
                              <th>Monto factura SM total</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(pay_provider, i) in data.pay_providers"
                              :key="i"
                            >
                              <td v-text="i + 1" />
                              <td v-text="pay_provider.name" />
                              <td v-text="pay_provider.quantity" />
                              <td
                                v-text="
                                  numberFormat(pay_provider.payment_total)
                                "
                              />
                              <td
                                v-text="
                                  numberFormat(pay_provider.bill_payment_total)
                                "
                              />
                            </tr>
                            <tr>
                              <td />
                              <td />
                              <td />
                              <td
                                class="font-weight-bold"
                                v-text="
                                  numberFormat(data.pay_providers_payment_total)
                                "
                              />
                              <td
                                class="font-weight-bold"
                                v-text="
                                  numberFormat(
                                    data.pay_providers_bill_payment_total
                                  )
                                "
                              />
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12"
              ><v-card class="mx-auto">
                <v-card-title>
                  <h2
                    class="text-caption"
                    v-text="
                      `${
                        data.provider_brand_id
                          ? `${data.provider_brand.provider_brand} | ${
                              data.provider_brand.bank.bank
                            } | ${
                              data.format == 1
                                ? `Cuenta ${data.provider_brand.account_number}`
                                : `CLABE ${data.provider_brand.clabe}`
                            }`
                          : `SIN CONCENTRADOR (${
                              data.format == 1 ? 'BBVA' : 'OTROS'
                            })`
                      }`
                    "
                  />
                </v-card-title>
                <v-divider />
                <v-card-text>
                  <v-row dense>
                    <v-col cols="12">
                      <v-simple-table dense>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Folio</th>
                              <th>Aseguradora</th>
                              <th>Hospital</th>
                              <th>Contratante</th>
                              <th>Doc. ID</th>
                              <th v-if="!data.provider_brand_id">Banco</th>
                              <th
                                v-if="
                                  !data.provider_brand_id && data.format == 1
                                "
                              >
                                Cuenta
                              </th>
                              <th
                                v-if="
                                  !data.provider_brand_id && data.format == 2
                                "
                              >
                                CLABE
                              </th>
                              <th>F. registro</th>
                              <th>Monto factura SM</th>
                              <th>F. recepción</th>
                              <th>F. promesa de pago</th>
                              <th>Monto a pagar</th>
                              <th>F. pago</th>
                              <th>Monto pagado</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(payment, j) in data.items" :key="j">
                              <td v-text="j + 1" />
                              <td v-text="payment.rha_bill.rha.folio" />
                              <td
                                v-text="payment.rha_bill.rha.insurance.name"
                              />
                              <td
                                v-text="
                                  payment.rha_bill.rha.provider.trade_name
                                "
                              />
                              <td
                                v-text="
                                  payment.rha_bill.rha.contractor
                                    ? payment.rha_bill.rha.contractor.name
                                    : '-'
                                "
                              />
                              <td v-text="payment.rha_bill_document" />
                              <td
                                v-if="!data.provider_brand_id"
                                v-text="payment.rha_bill.rha.provider.bank.bank"
                              />
                              <td
                                v-if="
                                  !data.provider_brand_id && data.format == 1
                                "
                                v-text="
                                  payment.rha_bill.rha.provider.account_number
                                "
                              />
                              <td
                                v-if="
                                  !data.provider_brand_id && data.format == 2
                                "
                                v-text="payment.rha_bill.rha.provider.clabe"
                              />
                              <td v-text="payment.created_at" />
                              <td
                                v-text="
                                  numberFormat(
                                    payment.rha_bill.insurance_pay_amount
                                  )
                                "
                              />
                              <td v-text="payment.rha_bill.reception_date" />
                              <td v-text="payment.rha_bill.pay_date" />
                              <td v-text="numberFormat(payment.amount)" />
                              <td
                                v-text="
                                  payment.paid_date ? payment.paid_date : '-'
                                "
                              />
                              <td
                                v-text="
                                  payment.paid_amount
                                    ? numberFormat(payment.paid_amount)
                                    : '-'
                                "
                              />
                              <td>
                                <v-icon
                                  v-if="payment.verify != null"
                                  small
                                  :color="payment.verify ? 'success' : 'error'"
                                  v-text="
                                    payment.verify ? 'mdi-check' : 'mdi-alert'
                                  "
                                />
                                <v-tooltip v-else left>
                                  <template v-slot:activator="{ on }">
                                    <v-btn
                                      class="ml-1"
                                      v-on="on"
                                      fab
                                      x-small
                                      color="warning "
                                      dark
                                      @click.prevent="
                                        paymentEditDialog(payment)
                                      "
                                    >
                                      <v-icon v-text="'mdi-pencil'" />
                                    </v-btn>
                                  </template>
                                  <span v-text="'Editar'" />
                                </v-tooltip>
                              </td>
                            </tr>
                            <tr>
                              <td />
                              <td />
                              <td />
                              <td />
                              <td />
                              <td v-if="!data.provider_brand_id" />
                              <td
                                v-if="
                                  !data.provider_brand_id && data.format == 1
                                "
                              />
                              <td
                                v-if="
                                  !data.provider_brand_id && data.format == 2
                                "
                              />
                              <td />
                              <td
                                class="font-weight-bold"
                                v-text="
                                  numberFormat(
                                    data.pay_providers_bill_payment_total
                                  )
                                "
                              />
                              <td />
                              <td />
                              <td
                                class="font-weight-bold"
                                v-text="
                                  numberFormat(data.pay_providers_payment_total)
                                "
                              />
                              <td />
                              <td />
                              <td />
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" sm="12" md="6" v-if="!data.bank_check">
              <v-btn
                v-if="data.format == 1"
                color="light-blue darken-1"
                class="mr-1"
                dark
                small
                @click.prevent="brandFileGenerated(data.id, data.folio, 1)"
              >
                BBVA Cuenta
              </v-btn>
              <v-btn
                v-if="
                  data.format == 1 &&
                  data.provider_brand &&
                  data.provider_brand.cie &&
                  data.provider_brand.cie_reference
                "
                color="cyan darken-2"
                class="mr-1"
                dark
                small
                @click.prevent="brandFileGenerated(data.id, data.folio, 3)"
              >
                BBVA CIE
              </v-btn>
              <v-btn
                v-if="data.format == 2"
                color="teal darken-3"
                dark
                small
                @click.prevent="brandFileGenerated(data.id, data.folio, 2)"
              >
                Interbancario
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              sm="12"
              md="6"
              class="text-right"
              v-if="!pay_verify_file && !data.bank_check"
            >
              <v-row dense>
                <v-col cols="4">
                  <v-file-input
                    v-model="pay_exec.document_receipt_0"
                    accept=".xlsx"
                    label="Comprobante de pago"
                    dense
                  />
                </v-col>
                <v-col cols="12" xs="12" md="4">
                  <v-select
                    label="Lectura formato"
                    v-model="pay_exec.format"
                    :items="formats"
                    item-value="id"
                    :item-text="(v) => v.name"
                    dense
                  />
                </v-col>
                <v-col cols="4">
                  <v-btn
                    block
                    color="warning"
                    small
                    @click.prevent="payVerifyFile"
                    :disabled="
                      !pay_exec.document_receipt_0 || pay_dialog_loading
                    "
                    :loading="pay_dialog_loading"
                  >
                    Cargar comprobante
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
            <v-col
              cols="12"
              sm="12"
              md="6"
              class="text-right"
              v-if="pay_verify_file && !data.bank_check"
            >
              <v-btn class="mr-1" small @click.prevent="payVerifyFileDefault">
                Cargar otro comprobante
              </v-btn>
              <v-btn
                small
                color="success"
                :disabled="!pay_verifieds"
                @click.prevent="payExec"
              >
                Guardar comprobante
              </v-btn>
            </v-col>
            <v-col cols="12" class="text-right" v-if="data.bank_check">
              <v-btn
                color="info"
                dark
                small
                :href="url_documents + '/rha_bill_payments/' + data.url_receipt"
                target="_blank"
              >
                Ver comprobante de pago
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="payment_group_dialog"
      scrollable
      persistent
      max-width="2000px"
    >
      <v-card
        tile
        v-if="payment_group_dialog"
        :loading="payment_group_dialog_loading"
        :disabled="payment_group_dialog_loading"
      >
        <v-toolbar dark dense>
          <v-toolbar-title v-text="`Lote ${data ? data.group : ''}`" />
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="payment_group_dialog = false">
              <v-icon v-text="'mdi-close'" />
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text v-if="data">
          <v-row class="pt-3">
            <v-col cols="12">
              <v-card class="mx-auto">
                <v-card-title>
                  <h2 class="text-caption" v-text="'ASEGURADORAS'" />
                </v-card-title>
                <v-divider />
                <v-card-text>
                  <v-row dense>
                    <v-col cols="12">
                      <v-simple-table dense>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Nombre</th>
                              <th>Ingresos</th>
                              <th>Monto pago total</th>
                              <th>Monto factura SM total</th>
                              <th>Honorarios Médicos</th>
                              <th />
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(pay_insurance, i) in data.pay_insurances"
                              :key="i"
                            >
                              <td v-text="i + 1" />
                              <td v-text="pay_insurance.name" />
                              <td v-text="pay_insurance.quantity" />
                              <td
                                v-text="
                                  numberFormat(pay_insurance.payment_total)
                                "
                              />
                              <td
                                v-text="
                                  numberFormat(pay_insurance.bill_payment_total)
                                "
                              />
                              <td
                                v-text="
                                  numberFormat(pay_insurance.letters_total)
                                "
                              />
                              <td>
                                <v-tooltip left>
                                  <template v-slot:activator="{ on }">
                                    <v-btn
                                      v-on="on"
                                      fab
                                      x-small
                                      color="teal darken-3"
                                      dark
                                      @click.prevent="
                                        insurancePayDetail(pay_insurance)
                                      "
                                    >
                                      <v-icon v-text="'mdi-group'" />
                                    </v-btn>
                                  </template>
                                  <span
                                    v-text="'Concentradores | Contratantes'"
                                  />
                                </v-tooltip>
                              </td>
                            </tr>
                            <tr>
                              <td />
                              <td />
                              <td />
                              <td
                                class="font-weight-bold"
                                v-text="
                                  numberFormat(
                                    data.pay_insurances_payment_total
                                  )
                                "
                              />
                              <td
                                class="font-weight-bold"
                                v-text="
                                  numberFormat(
                                    data.pay_insurances_bill_payment_total
                                  )
                                "
                              />
                              <td
                                class="font-weight-bold"
                                v-text="
                                  numberFormat(
                                    data.pay_insurances_letters_total
                                  )
                                "
                              />
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12">
              <v-card class="mx-auto">
                <v-card-title>
                  <h2 class="text-caption" v-text="'HOSPITALES'" />
                </v-card-title>
                <v-divider />
                <v-card-text>
                  <v-row dense>
                    <v-col cols="12">
                      <v-simple-table dense>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Nombre</th>
                              <th>Ingresos</th>
                              <th>Monto pago total</th>
                              <th>Monto factura SM total</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(pay_provider, i) in data.pay_providers"
                              :key="i"
                            >
                              <td v-text="i + 1" />
                              <td v-text="pay_provider.name" />
                              <td v-text="pay_provider.quantity" />
                              <td
                                v-text="
                                  numberFormat(pay_provider.payment_total)
                                "
                              />
                              <td
                                v-text="
                                  numberFormat(pay_provider.bill_payment_total)
                                "
                              />
                            </tr>
                            <tr>
                              <td />
                              <td />
                              <td />
                              <td
                                class="font-weight-bold"
                                v-text="
                                  numberFormat(data.pay_providers_payment_total)
                                "
                              />
                              <td
                                class="font-weight-bold"
                                v-text="
                                  numberFormat(
                                    data.pay_providers_bill_payment_total
                                  )
                                "
                              />
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col
              cols="12"
              v-for="(rha_bill_payment, i) in data.rha_bill_payments"
              :key="i"
            >
              <v-card class="mx-auto">
                <v-card-title>
                  <h2
                    class="text-caption"
                    v-text="
                      `${
                        rha_bill_payment.provider_brand_id
                          ? `${rha_bill_payment.folio} | ${
                              rha_bill_payment.provider_brand.provider_brand
                            } | ${
                              rha_bill_payment.provider_brand.bank.bank
                            } | ${
                              rha_bill_payment.format == 1
                                ? `Cuenta ${rha_bill_payment.provider_brand.account_number}`
                                : `CLABE ${rha_bill_payment.provider_brand.clabe}`
                            }`
                          : `SIN CONCENTRADOR (${
                              rha_bill_payment.format == 1 ? 'BBVA' : 'OTROS'
                            })`
                      }`
                    "
                  />
                </v-card-title>
                <v-divider />
                <v-card-text>
                  <v-row dense>
                    <v-col cols="12">
                      <v-simple-table dense>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Folio</th>
                              <th>Aseguradora</th>
                              <th>Hospital</th>
                              <th>Contratante</th>
                              <th>Doc. ID</th>
                              <th v-if="!rha_bill_payment.provider_brand_id">
                                Banco
                              </th>
                              <th
                                v-if="
                                  !rha_bill_payment.provider_brand_id &&
                                  rha_bill_payment.format == 1
                                "
                              >
                                Cuenta
                              </th>
                              <th
                                v-if="
                                  !rha_bill_payment.provider_brand_id &&
                                  rha_bill_payment.format == 2
                                "
                              >
                                CLABE
                              </th>
                              <th>F. registro</th>
                              <th>Monto factura SM</th>
                              <th>F. recepción</th>
                              <th>F. promesa de pago</th>
                              <th>Monto a pagar</th>
                              <th>F. pago</th>
                              <th>Monto pagado</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(payment, j) in rha_bill_payment.items"
                              :key="j"
                            >
                              <td v-text="j + 1" />
                              <td v-text="payment.rha_bill.rha.folio" />
                              <td
                                v-text="payment.rha_bill.rha.insurance.name"
                              />
                              <td
                                v-text="
                                  payment.rha_bill.rha.provider.trade_name
                                "
                              />
                              <td
                                v-text="
                                  payment.rha_bill.rha.contractor
                                    ? payment.rha_bill.rha.contractor.name
                                    : '-'
                                "
                              />
                              <td v-text="payment.rha_bill_document" />
                              <td
                                v-if="!rha_bill_payment.provider_brand_id"
                                v-text="payment.rha_bill.rha.provider.bank.bank"
                              />
                              <td
                                v-if="
                                  !rha_bill_payment.provider_brand_id &&
                                  rha_bill_payment.format == 1
                                "
                                v-text="
                                  payment.rha_bill.rha.provider.account_number
                                "
                              />
                              <td
                                v-if="
                                  !rha_bill_payment.provider_brand_id &&
                                  rha_bill_payment.format == 2
                                "
                                v-text="payment.rha_bill.rha.provider.clabe"
                              />
                              <td v-text="payment.created_at" />
                              <td
                                v-text="
                                  numberFormat(
                                    payment.rha_bill.insurance_pay_amount
                                  )
                                "
                              />
                              <td v-text="payment.rha_bill.reception_date" />
                              <td v-text="payment.rha_bill.pay_date" />
                              <td v-text="numberFormat(payment.amount)" />
                              <td
                                v-text="
                                  payment.paid_date ? payment.paid_date : '-'
                                "
                              />
                              <td
                                v-text="
                                  payment.paid_amount
                                    ? numberFormat(payment.paid_amount)
                                    : '-'
                                "
                              />
                            </tr>
                            <tr>
                              <td />
                              <td />
                              <td />
                              <td />
                              <td />
                              <td v-if="!rha_bill_payment.provider_brand_id" />
                              <td
                                v-if="
                                  !rha_bill_payment.provider_brand_id &&
                                  rha_bill_payment.format == 1
                                "
                              />
                              <td
                                v-if="
                                  !rha_bill_payment.provider_brand_id &&
                                  rha_bill_payment.format == 2
                                "
                              />
                              <td />
                              <td
                                class="font-weight-bold"
                                v-text="
                                  numberFormat(
                                    data.rha_bill_payment_amounts[i]
                                      .bill_payment_total
                                  )
                                "
                              />
                              <td />
                              <td />
                              <td
                                class="font-weight-bold"
                                v-text="
                                  numberFormat(
                                    data.rha_bill_payment_amounts[i]
                                      .payment_total
                                  )
                                "
                              />
                              <td />
                              <td
                                class="font-weight-bold"
                                v-text="
                                  numberFormat(
                                    data.rha_bill_payment_amounts[i].paid_total
                                  )
                                "
                              />
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="payment_report_dialog"
      scrollable
      persistent
      max-width="2000px"
    >
      <v-card
        tile
        v-if="payment_report_dialog"
        :loading="payment_report_dialog_loading"
        :disabled="payment_report_dialog_loading"
      >
        <v-toolbar dark dense>
          <v-toolbar-title v-text="`Reporte`" />
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="payment_report_dialog = false">
              <v-icon v-text="'mdi-close'" />
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-row class="pt-3">
            <v-col cols="12">
              <v-card class="mx-auto">
                <v-card-title>
                  <h2 class="text-caption" v-text="'FILTROS'" />
                </v-card-title>
                <v-divider />
                <v-card-text>
                  <v-form ref="form_payment_report" lazy-validation>
                    <v-row dense>
                      <v-col
                        cols="12"
                        xs="12"
                        :md="payment_report.filter == 1 ? 3 : 4"
                      >
                        <v-select
                          label="Tipo"
                          v-model="payment_report.filter"
                          :items="payment_report_filter_options"
                          item-value="id"
                          :item-text="(v) => v.value"
                          dense
                          :rules="rules.required"
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        xs="12"
                        :md="payment_report.filter == 1 ? 3 : 4"
                      >
                        <DatePicker
                          label="Fecha inicio*"
                          :model.sync="payment_report.start_date"
                          :rules="rules.required"
                          all_date
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        xs="12"
                        :md="payment_report.filter == 1 ? 3 : 4"
                      >
                        <DatePicker
                          label="Fecha termino*"
                          :model.sync="payment_report.end_date"
                          :rules="rules.required"
                          all_date
                        />
                      </v-col>
                      <v-col
                        v-if="payment_report.filter == 1"
                        cols="12"
                        xs="12"
                        md="3"
                      >
                        <v-autocomplete
                          label="Aseguradora(s)*"
                          v-model="payment_report.insurances"
                          :items="insurances"
                          item-value="id"
                          :item-text="(v) => v.name"
                          :loading="insurances_loading"
                          dense
                          multiple
                        />
                      </v-col>
                      <v-col cols="12">
                        <v-btn
                          color="info"
                          block
                          small
                          @click.prevent="paymentReport"
                          v-text="'Buscar'"
                        />
                      </v-col>
                    </v-row>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row v-if="data">
            <v-col cols="12">
              <v-card class="mx-auto">
                <v-card-title>
                  <h2 class="text-caption" v-text="'ASEGURADORAS'" />
                </v-card-title>
                <v-divider />
                <v-card-text>
                  <v-row dense>
                    <v-col cols="12">
                      <v-simple-table dense>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Nombre</th>
                              <th>Ingresos</th>
                              <th>Monto pago total</th>
                              <th>Monto factura SM total</th>
                              <th>Honorarios Médicos</th>
                              <th />
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(pay_insurance, i) in data.pay_insurances"
                              :key="i"
                            >
                              <td v-text="i + 1" />
                              <td v-text="pay_insurance.name" />
                              <td v-text="pay_insurance.quantity" />
                              <td
                                v-text="
                                  numberFormat(pay_insurance.payment_total)
                                "
                              />
                              <td
                                v-text="
                                  numberFormat(pay_insurance.bill_payment_total)
                                "
                              />
                              <td
                                v-text="
                                  numberFormat(pay_insurance.letters_total)
                                "
                              />
                              <td>
                                <v-tooltip left>
                                  <template v-slot:activator="{ on }">
                                    <v-btn
                                      v-on="on"
                                      fab
                                      x-small
                                      color="teal darken-3"
                                      dark
                                      @click.prevent="
                                        insurancePayDetail(pay_insurance)
                                      "
                                    >
                                      <v-icon v-text="'mdi-group'" />
                                    </v-btn>
                                  </template>
                                  <span
                                    v-text="'Concentradores | Contratantes'"
                                  />
                                </v-tooltip>
                              </td>
                            </tr>
                            <tr>
                              <td />
                              <td />
                              <td />
                              <td
                                class="font-weight-bold"
                                v-text="
                                  numberFormat(
                                    data.pay_insurances_payment_total
                                  )
                                "
                              />
                              <td
                                class="font-weight-bold"
                                v-text="
                                  numberFormat(
                                    data.pay_insurances_bill_payment_total
                                  )
                                "
                              />
                              <td
                                class="font-weight-bold"
                                v-text="
                                  numberFormat(
                                    data.pay_insurances_letters_total
                                  )
                                "
                              />
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12">
              <v-card class="mx-auto">
                <v-card-title>
                  <h2 class="text-caption" v-text="'HOSPITALES'" />
                </v-card-title>
                <v-divider />
                <v-card-text>
                  <v-row dense>
                    <v-col cols="12">
                      <v-simple-table dense>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Nombre</th>
                              <th>Ingresos</th>
                              <th>Monto pago total</th>
                              <th>Monto factura SM total</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(pay_provider, i) in data.pay_providers"
                              :key="i"
                            >
                              <td v-text="i + 1" />
                              <td v-text="pay_provider.name" />
                              <td v-text="pay_provider.quantity" />
                              <td
                                v-text="
                                  numberFormat(pay_provider.payment_total)
                                "
                              />
                              <td
                                v-text="
                                  numberFormat(pay_provider.bill_payment_total)
                                "
                              />
                            </tr>
                            <tr>
                              <td />
                              <td />
                              <td />
                              <td
                                class="font-weight-bold"
                                v-text="
                                  numberFormat(data.pay_providers_payment_total)
                                "
                              />
                              <td
                                class="font-weight-bold"
                                v-text="
                                  numberFormat(
                                    data.pay_providers_bill_payment_total
                                  )
                                "
                              />
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col
              cols="12"
              v-for="(rha_bill_payment, i) in data.rha_bill_payments"
              :key="i"
              v-if="rha_bill_payment.items.length > 0"
            >
              <v-card class="mx-auto">
                <v-card-title>
                  <h2
                    class="text-caption"
                    v-text="
                      `${
                        payment_report.filter == 1
                          ? `${rha_bill_payment.folio} | `
                          : ''
                      }${
                        rha_bill_payment.provider_brand_id > 0
                          ? `${
                              rha_bill_payment.provider_brand.provider_brand
                            } | ${
                              rha_bill_payment.provider_brand.bank.bank
                            } | ${
                              rha_bill_payment.format == 1
                                ? `Cuenta ${rha_bill_payment.provider_brand.account_number}`
                                : `CLABE ${rha_bill_payment.provider_brand.clabe}`
                            }`
                          : `SIN CONCENTRADOR (${
                              rha_bill_payment.format == 1 ? 'BBVA' : 'OTROS'
                            })`
                      }`
                    "
                  />
                </v-card-title>
                <v-divider />
                <v-card-text>
                  <v-row dense>
                    <v-col cols="12">
                      <v-simple-table dense>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Folio</th>
                              <th>Aseguradora</th>
                              <th>Hospital</th>
                              <th>RFC</th>
                              <th>Razón Social</th>
                              <th>Folio Fact.</th>
                              <th>Fecha Fact.</th>
                              <th>IVA Fact.</th>
                              <th>Monto Fact.</th>
                              <th>Contratante</th>
                              <th>Paciente</th>
                              <th>Siniestro</th>
                              <th>Doc. ID</th>
                              <th>Tipo Desc.</th>
                              <th v-if="!rha_bill_payment.provider_brand_id">
                                Banco
                              </th>
                              <th
                                v-if="
                                  !rha_bill_payment.provider_brand_id &&
                                  rha_bill_payment.format == 1
                                "
                              >
                                Cuenta
                              </th>
                              <th
                                v-if="
                                  !rha_bill_payment.provider_brand_id &&
                                  rha_bill_payment.format == 2
                                "
                              >
                                CLABE
                              </th>
                              <th>F. registro</th>
                              <th>F. pago aseg.</th>
                              <th>F. envío aseg.</th>
                              <th>Pago acumulado aseg.</th>
                              <th>Monto factura SM</th>
                              <th>N. crédito</th>
                              <th>Desc. tras. prop</th>
                              <th
                                v-if="payment_report.filter == 1"
                                style="min-width: 140px !important"
                              >
                                Desc. trasladado
                              </th>
                              <th>Dif. pago aseg.</th>
                              <th>Dif. días pago aseg.</th>
                              <th>F. recepción</th>
                              <th>F. promesa pago</th>
                              <th>Monto a pagar</th>
                              <th>F. pago</th>
                              <th>Monto pagado</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(payment, j) in rha_bill_payment.items"
                              :key="j"
                            >
                              <td v-text="j + 1" />
                              <td v-text="payment.rha_bill.rha.folio" />
                              <td
                                v-text="payment.rha_bill.rha.insurance.name"
                              />
                              <td
                                v-text="
                                  payment.rha_bill.rha.provider.trade_name
                                "
                              />
                              <td v-text="payment.code_tax" />
                              <td v-text="payment.legal_name" />
                              <td v-text="payment.folio" />
                              <td v-text="payment.date_bill_stamped" />
                              <td
                                v-text="numberFormat(payment.bills_tax_amount)"
                              />
                              <td v-text="numberFormat(payment.xml_amount)" />
                              <td
                                v-text="
                                  payment.rha_bill.rha.contractor
                                    ? payment.rha_bill.rha.contractor.name
                                    : '-'
                                "
                              />
                              <td
                                v-text="
                                  `${payment.rha_bill.rha.name} ${payment.rha_bill.rha.first_surname} ${payment.rha_bill.rha.second_surname}`
                                "
                              />
                              <td
                                v-text="
                                  payment.rha_bill.rha.sinister_number
                                    ? payment.rha_bill.rha.sinister_number
                                    : '-'
                                "
                              />
                              <td v-text="payment.rha_bill_document" />
                              <td
                                v-text="
                                  payment.rha_bill.rha_bill_discount_type
                                    .rha_bill_discount_type
                                "
                              />
                              <td
                                v-if="!rha_bill_payment.provider_brand_id"
                                v-text="payment.rha_bill.rha.provider.bank.bank"
                              />
                              <td
                                v-if="
                                  !rha_bill_payment.provider_brand_id &&
                                  rha_bill_payment.format == 1
                                "
                                v-text="
                                  payment.rha_bill.rha.provider.account_number
                                "
                              />
                              <td
                                v-if="
                                  !rha_bill_payment.provider_brand_id &&
                                  rha_bill_payment.format == 2
                                "
                                v-text="payment.rha_bill.rha.provider.clabe"
                              />
                              <td
                                v-text="
                                  payment.created_at
                                    ? payment.created_at.substring(0, 19)
                                    : '-'
                                "
                              />
                              <td
                                v-text="
                                  payment.rha_bill.insurance_paid_date
                                    ? payment.rha_bill.insurance_paid_date.substring(
                                        0,
                                        10
                                      )
                                    : '-'
                                "
                              />
                              <td>
                                {{
                                  payment.rha_bill.insurance_reception_date
                                    ? payment.rha_bill
                                        .insurance_reception_date +
                                      " " +
                                      payment.rha_bill.insurance_reception_time
                                    : "-"
                                }}
                              </td>
                              <td
                                v-text="
                                  payment.rha_bill.insurance_paid_amount
                                    ? numberFormat(
                                        payment.rha_bill.insurance_paid_amount
                                      )
                                    : '-'
                                "
                              />
                              <td
                                v-text="
                                  numberFormat(
                                    payment.rha_bill.insurance_pay_amount
                                  )
                                "
                              />
                              <td>
                                <div
                                  v-if="payment.note_add"
                                  class="text-center"
                                >
                                  <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                      <v-btn
                                        v-on="on"
                                        fab
                                        dark
                                        x-small
                                        class="ml-1"
                                        color="warning"
                                        @click="noteAddDialog(payment, i, j)"
                                      >
                                        <v-icon> mdi-file-plus </v-icon>
                                      </v-btn>
                                    </template>
                                    <span v-text="'Agregar nota de crédito'" />
                                  </v-tooltip>
                                </div>
                                <div v-else class="text-center">
                                  {{
                                    numberFormat(payment.rha_bill.notes_amount)
                                  }}
                                </div>
                              </td>
                              <td
                                v-text="
                                  numberFormat(
                                    payment.rha_bill
                                      .discount_transferred_amount_notes
                                  )
                                "
                              />
                              <td v-if="payment_report.filter == 1">
                                <v-text-field
                                  v-if="
                                    payment.rha_bill
                                      .discount_transferred_amount_edited
                                  "
                                  v-model="
                                    payment.rha_bill.discount_transferred_amount
                                  "
                                  type="number"
                                  dense
                                />
                                <span
                                  v-else
                                  v-text="
                                    numberFormat(
                                      payment.rha_bill
                                        .discount_transferred_amount
                                    )
                                  "
                                />
                              </td>
                              <td
                                v-text="
                                  payment.rha_bill.dif_insurance_pay != null
                                    ? numberFormat(
                                        payment.rha_bill.dif_insurance_pay
                                      )
                                    : '-'
                                "
                              />
                              <td
                                v-text="
                                  payment.rha_bill.dif_insurance_pay_days
                                    ? payment.rha_bill.dif_insurance_pay_days
                                    : '-'
                                "
                              />
                              <td v-text="payment.rha_bill.reception_date" />
                              <td v-text="payment.rha_bill.pay_date" />
                              <td v-text="numberFormat(payment.amount)" />
                              <td
                                v-text="
                                  payment.paid_date ? payment.paid_date : '-'
                                "
                              />
                              <td
                                v-text="
                                  payment.paid_amount
                                    ? numberFormat(payment.paid_amount)
                                    : '-'
                                "
                              />
                            </tr>
                            <tr>
                              <td />
                              <td />
                              <td />
                              <td />
                              <td />
                              <td />
                              <td />
                              <td />
                              <td />
                              <td />
                              <td />
                              <td />
                              <td />
                              <td />
                              <td />
                              <td v-if="!rha_bill_payment.provider_brand_id" />
                              <td
                                v-if="
                                  !rha_bill_payment.provider_brand_id &&
                                  rha_bill_payment.format == 1
                                "
                              />
                              <td
                                v-if="
                                  !rha_bill_payment.provider_brand_id &&
                                  rha_bill_payment.format == 2
                                "
                              />
                              <td />
                              <td />
                              <td />
                              <td
                                class="font-weight-bold"
                                v-text="
                                  numberFormat(
                                    data.rha_bill_payment_amounts[i]
                                      .bill_payment_total
                                  )
                                "
                              />
                              <td />
                              <td />
                              <td />
                              <td v-if="payment_report.filter == 1" />
                              <td />
                              <td />
                              <td />
                              <td />
                              <td
                                class="font-weight-bold"
                                v-text="
                                  numberFormat(
                                    data.rha_bill_payment_amounts[i]
                                      .payment_total
                                  )
                                "
                              />
                              <td />
                              <td
                                class="font-weight-bold"
                                v-text="
                                  numberFormat(
                                    data.rha_bill_payment_amounts[i].paid_total
                                  )
                                "
                              />
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col
              v-if="payment_report.filter == 1"
              cols="12"
              class="text-right"
            >
              <v-btn
                class="mr-1"
                x-small
                @click.prevent="discountTransferred(true)"
              >
                Editar desc. trasladados
                <v-icon right v-text="'mdi-pencil'" />
              </v-btn>
              <v-btn
                class="mr-1"
                x-small
                color="warning"
                @click.prevent="discountTransferred(false)"
              >
                Act. desc. trasladados
                <v-icon right v-text="'mdi-update'" />
              </v-btn>
              <v-btn x-small color="success" @click.prevent="reportFile">
                Excel
                <v-icon right v-text="'mdi-file'" />
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="pay_insurance_dialog"
      scrollable
      persistent
      max-width="1400px"
    >
      <v-card
        tile
        v-if="pay_insurance"
        :loading="pay_insurance_dialog_loading"
        :disabled="pay_insurance_dialog_loading"
      >
        <v-toolbar dark dense>
          <v-toolbar-title v-text="pay_insurance.name" />
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="pay_insurance_dialog = false">
              <v-icon v-text="'mdi-close'" />
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-row class="pt-3">
            <v-col cols="12">
              <v-card class="mx-auto">
                <v-card-title>
                  <h2
                    class="text-caption"
                    v-text="'CONCENTRADORES | CONTRATANTES'"
                  />
                </v-card-title>
                <v-divider />
                <v-card-text>
                  <v-row dense>
                    <v-col cols="12">
                      <v-simple-table dense>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Nombre</th>
                              <th>Ingresos</th>
                              <th>Monto pago total</th>
                              <th>Monto factura SM total</th>
                              <th>Honorarios Médicos</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(
                                contractor_brand, i
                              ) in pay_insurance.contractor_brands"
                              :key="i"
                            >
                              <td v-text="i + 1" />
                              <td v-text="contractor_brand.name" />
                              <td v-text="contractor_brand.quantity" />
                              <td
                                v-text="
                                  numberFormat(contractor_brand.payment_total)
                                "
                              />
                              <td
                                v-text="
                                  numberFormat(
                                    contractor_brand.bill_payment_total
                                  )
                                "
                              />
                              <td
                                v-text="
                                  numberFormat(contractor_brand.letters_total)
                                "
                              />
                            </tr>
                            <tr>
                              <td />
                              <td />
                              <td />
                              <td
                                class="font-weight-bold"
                                v-text="
                                  numberFormat(pay_insurance.payment_total)
                                "
                              />
                              <td
                                class="font-weight-bold"
                                v-text="
                                  numberFormat(pay_insurance.bill_payment_total)
                                "
                              />
                              <td
                                class="font-weight-bold"
                                v-text="
                                  numberFormat(pay_insurance.letters_total)
                                "
                              />
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="payment_edit_dialog"
      scrollable
      persistent
      max-width="700px"
    >
      <v-card
        tile
        v-if="payment_edit"
        :loading="payment_edit_dialog_loading"
        :disabled="payment_edit_dialog_loading"
      >
        <v-toolbar dark dense>
          <v-toolbar-title
            v-text="`Pago | Folio ${payment_edit.rha_bill.rha.folio}`"
          />
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="payment_edit_dialog = false">
              <v-icon v-text="'mdi-close'" />
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-row class="pt-3">
            <v-col cols="12">
              <v-card class="mx-auto">
                <v-card-title>
                  <h2 class="text-caption" v-text="'Detalle'" />
                </v-card-title>
                <v-divider />
                <v-card-text>
                  <v-form
                    v-on:submit.prevent
                    ref="form_payment_edit"
                    lazy-validation
                  >
                    <v-row dense>
                      <v-col cols="12" xs="12" md="6">
                        <ViewData
                          label="Aseguradora"
                          :value="payment_edit.rha_bill.rha.insurance.name"
                        />
                      </v-col>
                      <v-col cols="12" xs="12" md="6">
                        <ViewData
                          label="Hospital"
                          :value="payment_edit.rha_bill.rha.provider.trade_name"
                        />
                      </v-col>
                      <v-col cols="12" xs="12" md="6">
                        <ViewData
                          label="Doc. ID"
                          :value="payment_edit.rha_bill_document"
                        />
                      </v-col>
                      <v-col cols="12" xs="12" md="6">
                        <ViewData
                          label="F. registro"
                          :value="payment_edit.created_at"
                        />
                      </v-col>
                      <v-col cols="12" xs="12" md="6">
                        <ViewData
                          label="Monto factura SM"
                          :value="
                            numberFormat(
                              payment_edit.rha_bill.insurance_pay_amount
                            )
                          "
                        />
                      </v-col>
                      <v-col cols="12" xs="12" md="6">
                        <ViewData
                          label="F. recepción"
                          :value="payment_edit.rha_bill.reception_date"
                        />
                      </v-col>
                      <v-col cols="12" xs="12" md="6">
                        <ViewData
                          label="Beneficiario"
                          :value="payment_edit.beneficiary"
                        />
                      </v-col>
                      <v-col cols="12" xs="12" md="6">
                        <ViewData
                          label="Cuenta"
                          :value="payment_edit.beneficiary_account"
                        />
                      </v-col>
                      <v-col cols="12" xs="12" md="6">
                        <v-text-field
                          v-model="payment_edit.amount"
                          label="Monto a pagar"
                          type="number"
                          dense
                          :rules="rules.required"
                        />
                      </v-col>
                      <v-col cols="12">
                        <v-btn
                          block
                          small
                          :disabled="payment_edit_dialog_loading"
                          color="warning"
                          @click.prevent="paymentEdit"
                        >
                          Editar
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="note_add_dialog" scrollable persistent max-width="900px">
      <v-card
        tile
        :disabled="note_add_dialog_loading"
        :loading="note_add_dialog_loading"
      >
        <v-toolbar dark dense>
          <v-toolbar-title>Agregar nota de crédito</v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="note_add_dialog = false">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text v-if="note_add_data">
          <v-row>
            <v-col cols="12">
              <v-spacer />
            </v-col>
            <v-col cols="12">
              <v-card class="mx-auto">
                <v-card-title>
                  <h2 class="text-caption" v-text="'DETALLE'" />
                </v-card-title>
                <v-divider />
                <v-card-text>
                  <v-row dense>
                    <v-col cols="12" xs="12" md="4">
                      <ViewData
                        label="Folio"
                        :value="note_add_data.item.folio"
                      />
                    </v-col>
                    <v-col cols="12" xs="12" md="4">
                      <ViewData
                        label="Doc. ID"
                        :value="note_add_data.item.rha_bill_document"
                      />
                    </v-col>
                    <v-col cols="12" xs="12" md="4">
                      <ViewData
                        label="Folio factura"
                        :value="
                          note_add_data.item.rha_bill.rha_bill_status.folio
                        "
                      />
                    </v-col>
                    <v-col cols="12" xs="12" md="4">
                      <ViewData
                        label="monto factura"
                        :value="
                          numberFormat(
                            note_add_data.item.rha_bill.rha_bill_status
                              .xml_amount
                          )
                        "
                      />
                    </v-col>
                    <v-col cols="12" xs="12" md="4">
                      <ViewData
                        label="fecha factura"
                        :value="
                          note_add_data.item.rha_bill.rha_bill_status
                            .date_bill_stamped
                        "
                      />
                    </v-col>
                    <v-col
                      v-if="
                        note_add_data.item.rha_bill.rha_bill_status.url_bill
                      "
                      cols="12"
                      xs="12"
                      md="4"
                    >
                      <div>
                        <span
                          class="text-caption font-weight-bold"
                          v-text="'Factura PDF'"
                        />
                        <br />
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              icon
                              small
                              v-on="on"
                              :href="
                                url_documents +
                                '/rs_service_bill_documents/' +
                                note_add_data.item.rha_bill.rha_bill_status
                                  .url_bill
                              "
                              target="_blank"
                            >
                              <v-icon small>mdi-eye</v-icon>
                            </v-btn>
                          </template>
                          <span v-text="'Ver'" />
                        </v-tooltip>
                      </div>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12">
              <v-card class="mx-auto">
                <v-card-title>
                  <h2 class="text-caption" v-text="'NOTA DE CRÉDITO'" />
                </v-card-title>
                <v-divider />
                <v-card-text>
                  <v-form
                    v-on:submit.prevent
                    ref="form_note_add_data"
                    lazy-validation
                  >
                    <v-row dense>
                      <v-col cols="12" xs="12" md="4">
                        <v-text-field
                          v-model="note_add_data.folio"
                          label="Folio*"
                          type="text"
                          dense
                          :rules="rules.required"
                        />
                      </v-col>
                      <v-col cols="12" xs="12" md="4">
                        <DatePicker
                          label="Fecha*"
                          :model.sync="note_add_data.date_bill_stamped"
                          :rules="rules.required"
                        />
                      </v-col>
                      <v-col cols="12" xs="12" md="4">
                        <v-text-field
                          label="Monto*"
                          v-model="note_add_data.xml_amount"
                          :rules="rules.required"
                          type="number"
                          dense
                          min="0"
                        />
                      </v-col>
                    </v-row>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12">
              <v-btn block dark small color="warning" @click.prevent="noteAdd">
                Agregar
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <FaqDlg v-model="faqs_dlg" :faqs="faqs" />
  </v-card>
</template>

<script>
import Axios from "axios";
import {
  URL_API,
  headersToken,
  rules,
  msgConfirm,
  msgAlert,
  URL_DOCUMENTS,
  dateTimeNow,
} from "../../control";
import { storeUpdate } from "../../requests/pageRequest";
import BtnCircle from "../../components/BtnCircle.vue";
import DatePicker from "../../components/DatePicker.vue";
import ViewData from "../../components/ViewData.vue";
import { index } from "../../requests/pageRequest";
import FaqDlg from "../../components/FaqDlg.vue";

export default {
  components: {
    BtnCircle,
    DatePicker,
    ViewData,
    FaqDlg,
  },
  data() {
    return {
      api: "bills",
      page_route: "facturacion",
      login: this.$store.getters.getLogin,
      url_documents: URL_DOCUMENTS,
      loading: false,
      rules: rules(),
      data_table: [],
      data: null,
      search_table: "",
      headers: [
        { text: "#", filterable: true, value: "index" },
        { text: "Folio", filterable: true, value: "folio" },
        {
          text: "Concentrador",
          filterable: true,
          value: "provider_brand_name",
        },
        { text: "Fecha", filterable: true, value: "created_at" },
        { text: "Lote", filterable: true, value: "group" },
        { text: "Monto", filterable: true, value: "amount" },
        {
          text: "",
          value: "action",
          sortable: false,
          width: "250px",
        },
      ],
      filter: 1,
      filter_options: [
        { id: 1, value: "PENDIENTES" },
        { id: 2, value: "PAGADO SIN VERIFICAR" },
        { id: 3, value: "VERIFICADOS Y TERMINADOS" },
      ],
      pay_dialog: false,
      pay_dialog_loading: false,
      pay_dialog_form: false,
      pay_dialog_form_loading: false,
      pay_exec: {
        rha_bill_payment_items: null,
        document_receipt_0: null,
        format: null,
      },
      start_date: null,
      end_date: null,
      pay_verify_file: false,
      pay_verifieds: null,
      payment_group_dialog: false,
      payment_group_dialog_loading: false,
      payment_report: null,
      payment_report_dialog: false,
      payment_report_dialog_loading: false,
      payment_report_filter_options: [
        {
          id: 1,
          value: "PAGADOS",
        },
        {
          id: 2,
          value: "POR PAGAR",
        },
      ],
      insurances: [],
      insurances_loading: false,
      formats: [
        {
          id: 1,
          name: "BBVA",
        },
        {
          id: 2,
          name: "INTERBANCARIO",
        },
      ],
      pay_insurance: null,
      pay_insurance_dialog: null,
      pay_insurance_dialog_loading: null,
      payment_edit: null,
      payment_edit_dialog: false,
      payment_edit_dialog_loading: false,
      note_add_data: null,
      note_add_dialog: false,
      note_add_dialog_loading: false,
      faqs: [],
      faqs_dlg: false,
      faqs_ldg: true,
    };
  },
  methods: {
    handleTableColumns() {},
    getData() {
      this.loading = true;

      Axios.get(
        URL_API + `/bills/rha/payment`,
        headersToken(this.login.token)
      ).then((response) => {
        this.data_table = response.data.data;
        this.loading = false;
      });
    },
    payDetail(rha_bill_payment_id) {
      this.data = null;
      this.pay_dialog_loading = true;
      this.pay_dialog = true;
      this.pay_verify_file = false;
      this.pay_exec.document_receipt_0 = null;
      this.pay_exec.format = null;
      this.pay_verifieds = null;

      Axios.get(
        URL_API + `/bills/rha/payment/${rha_bill_payment_id}`,
        headersToken(this.login.token)
      ).then((response) => {
        this.data = response.data.data;
        this.pay_exec.format = this.data.format;

        this.pay_dialog_loading = false;
      });
    },
    brandFileGenerated(rha_bill_payment_id, folio, format) {
      this.pay_dialog_loading = true;

      Axios.get(
        URL_API + `/bills/rha/payment/${rha_bill_payment_id}/file/${format}`,
        headersToken(this.login.token)
      ).then((response) => {
        //console.log(response.data);
        this.$swal.fire(
          msgAlert(
            response.data.success ? "success" : "error",
            response.data.message
          )
        );

        if (response.data.success) {
          this.pay_dialog_loading = false;

          const linkSource = `data:application/txt;base64,${response.data.data}`;
          const downloadLink = document.createElement("a");
          const fileName = `SM_${folio}_${response.data.now}_${format}.txt`;

          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
        } else {
          console.log(response.data.message);
        }
      });
    },
    payVerifyFile() {
      this.pay_dialog_loading = true;
      this.pay_verify_file = false;
      this.pay_verifieds = null;
      this.pay_exec.rha_bill_payment_items = this.data.items;
      this.pay_exec.rha_bill_payment_id = this.data.id;

      storeUpdate(
        "bills/rha/payment/receipt/analyze",
        this.login.token,
        null,
        this.pay_exec,
        null
      ).then((response) => {
        //console.log(response);
        this.$swal.fire(
          msgAlert(response.success ? "success" : "error", response.message)
        );

        if (response.success) {
          this.data.items = response.data;
          this.pay_exec.rha_bill_payment_items = response.data;
          this.pay_verifieds = response.pay_verifieds;
          this.pay_verify_file = true;
        } else {
          console.log(response.message);
        }

        this.pay_dialog_loading = false;
      });
    },
    payVerifyFileDefault() {
      this.pay_verify_file = false;
      //this.pay_exec.document_receipt_0 = null;
      this.pay_verifieds = null;

      for (let item of this.data.items) {
        item.paid_date = null;
        item.paid_amount = null;
        item.verify = null;
      }
    },
    payExec() {
      this.$swal
        .fire(
          msgConfirm(`¿Confirma guardar comprobante para la orden de pago?`)
        )
        .then((response) => {
          if (response.isConfirmed) {
            this.pay_dialog_loading = true;

            storeUpdate(
              "bills/rha/payment/receipt",
              this.login.token,
              null,
              this.pay_exec,
              null
            ).then((response) => {
              //console.log(response.data);
              this.$swal.fire(
                msgAlert(
                  response.success ? "success" : "error",
                  response.message
                )
              );

              if (response.success) {
                this.pay_dialog = false;
                this.pay_dialog_loading = false;
                this.getData();
              } else {
                console.log(response.message);
              }
            });
          }
        });
    },
    paymentGroup(group) {
      this.payment_group_dialog = true;
      this.data = null;
      this.payment_group_dialog_loading = true;

      Axios.get(
        `${URL_API}/bills/rha/payment/group/${group}`,
        headersToken(this.login.token)
      ).then((response) => {
        this.data = response.data.data;
        this.data.group = group;
        // console.log(this.payment_group);
        this.payment_group_dialog_loading = false;
      });
    },
    paymentReportDialog() {
      this.payment_report_dialog_loading = false;
      this.data = null;
      this.payment_report = {
        filter: 1,
        start_date: dateTimeNow().substring(0, 10),
        end_date: dateTimeNow().substring(0, 10),
        insurances: [],
      };
      this.payment_report_dialog = true;
    },
    paymentReport() {
      if (this.$refs.form_payment_report.validate()) {
        this.data = null;
        this.payment_report_dialog_loading = true;
        const api = `${URL_API}/bills/rha/payment/report/${
          this.payment_report.filter == 1 ? "paid" : "pay"
        }`;

        Axios.post(
          api,
          this.payment_report,
          headersToken(this.login.token)
        ).then((response) => {
          //console.log(response);

          if (response.data.success) {
            this.data = response.data.data;
            this.payment_report_dialog_loading = false;
          } else {
            console.log(response.data.message);
          }
        });
      } else {
        this.$swal.fire(
          msgAlert(
            "error",
            "Formulario invalido, revisa los detalles señalados"
          )
        );
      }
    },
    discountTransferred(edit) {
      if (edit) {
        for (let rha_bill_payment of this.data.rha_bill_payments) {
          for (let payment of rha_bill_payment.items) {
            payment.rha_bill.discount_transferred_amount =
              payment.rha_bill.discount_transferred_amount_notes;
            payment.rha_bill.discount_transferred_amount_edited = true;
          }
        }
      } else {
        this.$swal
          .fire(msgConfirm(`¿Confirma guardar la información?`))
          .then((response) => {
            if (response.isConfirmed) {
              this.payment_report_dialog_loading = true;

              Axios.post(
                URL_API + `/bills/rha/payment/discount/transferred/amount/save`,
                this.data,
                headersToken(this.login.token)
              ).then((response) => {
                console.log(response.data);
                this.$swal.fire(
                  msgAlert(
                    response.data.success ? "success" : "error",
                    response.data.message
                  )
                );

                if (response.data.success) {
                  this.paymentReport();
                } else {
                  console.log(response.data.message);
                }
              });
            }
          });
      }
    },
    reportFile() {
      this.payment_report_dialog_loading = true;

      Axios.post(
        URL_API + `/bills/rha/payment/excel`,
        this.data,
        headersToken(this.login.token)
      ).then((response) => {
        // console.log(response.data);
        this.$swal.fire(
          msgAlert(
            response.data.success ? "success" : "error",
            response.data.message
          )
        );

        if (response.data.success) {
          this.payment_report_dialog_loading = false;

          const linkSource = `data:application/xlsx;base64,${response.data.data}`;
          const downloadLink = document.createElement("a");
          const fileName = `sm_op_report.xlsx`;

          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
        } else {
          console.log(response.data.message);
        }
      });
    },
    getclassRowStatusAccount(v) {
      if (v.insurance_payment_check) return "orange lighten-3";
      if (v.exist > 1) return "red lighten-3";
      if (v.rha_bill_insurance_payment_id) return "green lighten-3";
    },
    insurancePayDetail(pay_insurance) {
      this.pay_insurance = null;
      this.pay_insurance_dialog_loading = true;
      this.pay_insurance_dialog = true;
      this.pay_insurance = pay_insurance;
      this.pay_insurance_dialog_loading = false;
    },
    paymentEditDialog(payment) {
      this.payment_edit = Object.assign({}, payment);
      this.payment_edit_dialog_loading = false;
      this.payment_edit_dialog = true;
    },
    paymentEdit() {
      if (this.$refs.form_payment_edit.validate()) {
        this.$swal
          .fire(msgConfirm(`¿Confirma editar la información de pago?`))
          .then((response) => {
            if (response.isConfirmed) {
              this.payment_edit_dialog_loading = true;

              this.payment_edit._method = "PATCH";

              Axios.post(
                `${URL_API}/bills/rha/payment/items/${this.payment_edit.id}`,
                this.payment_edit,
                headersToken(this.login.token)
              ).then((response) => {
                //console.log(response.data);
                this.$swal.fire(
                  msgAlert(
                    response.data.success ? "success" : "error",
                    response.data.message
                  )
                );

                if (response.data.success) {
                  this.payment_edit_dialog = false;
                  this.payDetail(this.data.id);
                  this.getData();
                } else {
                  console.log(response.data.message);
                }

                this.payment_edit_dialog_loading = false;
              });
            }
          });
      } else {
        this.$swal.fire(
          msgAlert(
            "error",
            "Formulario invalido, revisa los detalles señalados"
          )
        );
      }
    },
    noteAddDialog(item, i, j) {
      let date = dateTimeNow();
      date = date.substring(0, 10);

      this.note_add_data = {
        id: null,
        active: true,
        rha_bill_status_id: item.rha_bill.rha_bill_status.id,
        folio: "",
        date_bill_stamped: date,
        xml_amount: "0",
        item: item,
        i: i,
        j: j,
      };
      this.note_add_dialog_loading = false;
      this.note_add_dialog = true;
    },
    noteAdd() {
      if (this.$refs.form_note_add_data.validate()) {
        this.$swal
          .fire(msgConfirm(`¿Confirma agregar la nota de crédito?`))
          .then((resp) => {
            if (resp.isConfirmed) {
              this.note_add_dialog_loading = true;

              Axios.post(
                URL_API + "/bills/rha/notes",
                this.note_add_data,
                headersToken(this.login.token)
              ).then((resp) => {
                this.$swal.fire(
                  msgAlert(
                    resp.data.success ? "success" : "error",
                    resp.data.message
                  )
                );

                if (resp.data.success) {
                  this.data.rha_bill_payments[this.note_add_data.i].items[
                    this.note_add_data.j
                  ].rha_bill.notes_amount = this.note_add_data.xml_amount;
                  this.data.rha_bill_payments[this.note_add_data.i].items[
                    this.note_add_data.j
                  ].note_add = false;
                  this.note_add_dialog = false;
                } else {
                  console.log(resp.data.message);
                }

                this.note_add_dialog_loading = false;
              });
            }
          });
      } else {
        this.$swal.fire(
          msgAlert(
            "error",
            "Formulario invalido, revisa los detalles señalados"
          )
        );
      }
    },
    numberFormat(v) {
      return Number(parseFloat(v).toFixed(2)).toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });
    },
  },
  mounted() {
    Axios.get(
      URL_API + "/faqs/faq_interfaces/" + this.$route.meta.title,
      headersToken(this.login.token)
    ).then((resp) => {
      this.faqs = resp.data.data;
      this.faqs_ldg = false;
    });

    index("insurances", this.login.token, []).then((response) => {
      this.insurances = response.data;
      this.insurances_loading = false;
    });

    const dateTime = dateTimeNow();
    this.start_date = dateTime.substring(0, 10);
    this.end_date = dateTime.substring(0, 10);

    this.getData();
  },
  computed: {},
};
</script>